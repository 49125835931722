export const About = () =>
  localStorage.isAboutViewed ? null : (
    <dialog
      open
      style={{
        top: `1rem`,
        maxWidth: `55ch`,
        boxShadow: `0 0 1rem #000`,
        zIndex: `2`,
      }}
    >
      <form method="dialog">
        <h1
          style={{
            marginTop: `0`,
          }}
        >
          About <strong>Center of My Screen</strong>
        </h1>
        <p>
          Find the physical center of your screen on any device with this
          minimalist, installable web app.
        </p>
        <h2>Multiple Screens</h2>
        <p>
          The "Second Window" button can help you do this for multiple screens
          or sections of your screen at the same time.
        </p>
        <h2>Ruler App</h2>
        <p>
          Provide your diagonal screen size to display a true-scale ruler
          onscreen. Enter the size in inches, cm, parsecs &mdash; it's up to
          you!
        </p>
        <menu
          style={{
            padding: `0`,
          }}
        >
          <button
            value="default"
            onClick={() => {
              localStorage.isAboutViewed = true;
            }}
          >
            Okay, don't show this again.
          </button>
        </menu>
      </form>
    </dialog>
  );
