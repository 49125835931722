/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

import paypal from "./paypal.png";

export const supportStyles = css({
  gridColumn: `2 / 3`,
  gridRow: `2 / 3`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  border: `0`,
  textAlign: `center`,
  fontSize: `0.8125rem`,
  color: `black`,
  textDecoration: `none`,
  background: `rgba(239, 239, 239, 0.5)`,
  cursor: `pointer`,
  overflow: `hidden`,
  wordBreak: `break-all`,
  hyphens: `auto`,

  "@media (max-width: 600px)": {
    gridColumn: `1 / 3`,
  },

  img: {
    width: `80%`,
  },
});

export const Support = () => {
  return (
    <a
      href="https://www.paypal.com/donate/?hosted_button_id=YAGF2B4YTHG5L"
      target="_blank"
      rel="noreferrer"
      css={supportStyles}
    >
      Support
      <br />
      <img src={paypal} alt="with PayPal" />
    </a>
  );
};
