/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode } from "react";

export const quadrantStyles = css({
  display: `grid`,
  gridTemplateColumns: `repeat(4, minmax(0, 1fr))`,
  gridTemplateRows: `repeat(4, minmax(0, 1fr))`,
  backgroundImage: `
    linear-gradient(
      to right,
      transparent 0%,
      transparent calc(25% - 1px),
      #efefef calc(25% - 1px),
      #efefef calc(25% + 1px),
      transparent calc(25% + 1px),
      transparent calc(50% - 1px),
      #efefef calc(50% - 1px),
      #efefef calc(50% + 1px),
      transparent calc(50% + 1px),
      transparent calc(75% - 1px),
      #efefef calc(75% - 1px),
      #efefef calc(75% + 1px),
      transparent calc(75% + 1px),
      transparent 100%
    ),
    linear-gradient(
      to bottom,
      transparent 0%,
      transparent calc(25% - 1px),
      #efefef calc(25% - 1px),
      #efefef calc(25% + 1px),
      transparent calc(25% + 1px),
      transparent calc(50% - 1px),
      #efefef calc(50% - 1px),
      #efefef calc(50% + 1px),
      transparent calc(50% + 1px),
      transparent calc(75% - 1px),
      #efefef calc(75% - 1px),
      #efefef calc(75% + 1px),
      transparent calc(75% + 1px),
      transparent 100%
    )
  `,
});

export const screenGridStyles = css({
  display: `grid`,
  gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
  gridTemplateRows: `repeat(2, minmax(0, 1fr))`,
  gap: `2px 2px`,
  position: `fixed`,
  top: `0`,
  left: `0`,
  width: `100vw`,
  height: `100vh`,
  zIndex: `1`,
});

export const crosshairHorizontalStyles = css({
  position: `absolute`,
  top: `calc(50% - 1px)`,
  left: `0`,
  width: `100%`,
  height: `2px`,
  background: `black`,
});
export const crosshairVerticalStyles = css({
  position: `absolute`,
  top: `0`,
  left: `calc(50% - 1px)`,
  width: `2px`,
  height: `100%`,
  background: `black`,
});

export const centerDotStyles = css({
  position: `absolute`,
  top: `calc(50% - 1px)`,
  left: `calc(50% - 1px)`,
  width: `2px`,
  height: `2px`,
  background: `red`,
});

export interface ScreenGridProps {
  quadrantLeftTop?: ReactNode;
  quadrantRightTop?: ReactNode;
  quadrantLeftBottom?: ReactNode;
  quadrantRightBottom?: ReactNode;
}

export const ScreenGrid = (props: ScreenGridProps) => {
  const quads = React.useMemo(() => {
    const quadContent = [
      props.quadrantLeftTop,
      props.quadrantRightTop,
      props.quadrantLeftBottom,
      props.quadrantRightBottom,
    ];

    return Array(4)
      .fill(0)
      .map((zero, index) => (
        <div key={index} css={quadrantStyles}>
          {quadContent[index]}
        </div>
      ));
  }, [
    props.quadrantLeftBottom,
    props.quadrantLeftTop,
    props.quadrantRightBottom,
    props.quadrantRightTop,
  ]);

  return (
    <div css={screenGridStyles}>
      {quads}
      <div css={crosshairHorizontalStyles} />
      <div css={crosshairVerticalStyles} />
      <div css={centerDotStyles} />
    </div>
  );
};
